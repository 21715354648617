<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M12.862 4.39075L13.8046 5.33342L7.99998 11.1381L2.19531 5.33341L3.13798 4.39075L7.99998 9.25275L12.862 4.39075Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-alert-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 16 16'
    }
  }
}
</script>
